import { isBooleanListFilter, ListFilter, ListItemKey } from '@gain/rpc/list-model'
import { listAndFilter, listFilter, listFilters, listOrFilter } from '@gain/rpc/utils'
import { isDefined } from '@gain/utils/common'
import { formatISO } from 'date-fns/formatISO'
import { startOfDay } from 'date-fns/startOfDay'

import {
  FilterAutocompleteOptionsValue,
  FilterAutocompleteValue,
  FilterCityValue,
  FilterConfig,
  FilterConfigAutocomplete,
  FilterConfigCity,
  FilterConfigMap,
  FilterConfigRange,
  FilterConfigRangeCurrency,
  FilterCurrencyRangeValue,
  FilterRangeValue,
  FilterRangeValuePart,
  FilterValueMap,
} from './filter-config/filter-config-model'
import { FilterModel } from './filter-model'
import { FilterValueItem } from './filter-value-model'

function autocompleteFilterOptionsToListFilter<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  filter: FilterConfigAutocomplete<Item, FilterField>,
  value: FilterAutocompleteOptionsValue,
  exclude?: boolean
): ListFilter<Item> | null {
  if (value.value.length === 0) {
    return null
  }

  const andOrFilter = listFilter<Item>(
    '',
    value.mode === 'all' ? 'and' : 'or',
    value.value.map((option) => listFilter<Item, '=', FilterField>(filter.id, '=', option as never))
  )

  if (exclude) {
    return listFilter<Item>('', 'not', [andOrFilter])
  }

  return andOrFilter
}

function autocompleteFilterToListFilter<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(filter: FilterConfigAutocomplete<Item, FilterField>, value: FilterAutocompleteValue) {
  if (value === null || (value.include.value.length === 0 && value.exclude.value.length === 0)) {
    return null
  }

  return listAndFilter(
    listFilters(
      autocompleteFilterOptionsToListFilter<Item, FilterField>(filter, value.include),
      autocompleteFilterOptionsToListFilter<Item, FilterField>(filter, value.exclude, true)
    )
  )
}

const parseDate = (value: string) => {
  const [year, month, date] = value.split('-').map((part) => parseInt(part, 10))
  return new Date(year, month - 1, date)
}

export function isFilterConfigRange<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  filter: FilterConfigRange<Item, FilterField> | FilterConfigRangeCurrency<Item, FilterField>
): filter is FilterConfigRange<Item, FilterField> {
  return 'valueType' in filter
}

function rangeFilterToListFilter<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  filter: FilterConfigRange<Item, FilterField> | FilterConfigRangeCurrency<Item, FilterField>,
  value: FilterRangeValue
) {
  if (value === null || value.every((item) => item === null)) {
    return null
  }

  const hasMinAndMaxValues = value.filter(isDefined).length > 1

  // If we have a maxField then we filter the range on two different fields, so we should
  // reverse the order of the fields if both ranges are defined
  const shouldReverse = 'maxField' in filter && filter.maxField && hasMinAndMaxValues
  const maxField = 'maxField' in filter && filter.maxField ? filter.maxField : filter.id

  const result = value
    .map((item, index) => {
      if (item === null) {
        return null
      }

      // Format "date" ranges
      let part: FilterRangeValuePart = item
      const field: ListItemKey<Item> = index === (shouldReverse ? 1 : 0) ? filter.id : maxField
      if (isFilterConfigRange(filter) && filter.valueType === 'date' && typeof part === 'string') {
        part = formatISO(startOfDay(parseDate(part)))
      }

      return listFilter<Item, '>=' | '<=', ListItemKey<Item>>(
        field,
        index === 0 ? '>=' : '<=',
        // Ideally, we should enforce that item is a valid list filter value for the given key
        part as never
      )
    })
    .filter(isDefined)

  if (result.length === 1) {
    return result[0]
  }

  return listAndFilter(result)
}

function cityFilterToListFilter<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(filter: FilterConfigCity<Item, FilterField>, value: FilterCityValue) {
  if (value === null) {
    return null
  }

  // a city filter is mapped to an or filter containing an array of cities that can match
  // eg: or(and("Amsterdam", "Noord-Holland", "NL"), and("Maurik", "Gelderland", "NL"))
  return listOrFilter<Item>(
    value.map((item) =>
      listFilter<Item>(
        '',
        'and',
        listFilters<Item>(
          listFilter<Item, '=', FilterField>(filter.id, '=', item.city as never),
          filter.regionField &&
            listFilter<Item, '=', ListItemKey<Item>>(filter.regionField, '=', item.region as never),
          listFilter<Item, '=', ListItemKey<Item>>(
            filter.countryCodeField,
            '=',
            item.countryCode as never
          )
        )
      )
    )
  )
}

/**
 * Converts a {FilterType} and value to {ListFilter}.
 */
function filterTypeToListFilter<
  Item extends object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>,
  Filter extends FilterConfig<Item, FilterField> = FilterConfig<Item, FilterField>,
  Type extends Filter['type'] = Filter['type'],
  Value extends FilterValueMap[Type] = FilterValueMap[Type]
>(filter: Filter | undefined, value: Value): ListFilter<Item> | null {
  if (!filter) {
    return null
  }

  switch (filter.type) {
    case 'autocomplete':
      return autocompleteFilterToListFilter(filter, value as FilterAutocompleteValue)
    case 'checkbox':
      return value === true ? listFilter(filter.id, '=', value as never) : null
    case 'range-currency':
      return rangeFilterToListFilter(filter, value as FilterCurrencyRangeValue)
    case 'range':
      return rangeFilterToListFilter(filter, value as FilterRangeValue)
    case 'geo-point':
      // The within operator expects a type that does not directly map to the property type
      // which is how all the other filters work. We need to have a look at the typings to
      // solve this
      return value !== null ? listFilter(filter.id, 'within', value as never) : null
    case 'geo-polygon':
      // The within operator expects a type that does not directly map to the property type
      // which is how all the other filters work. We need to have a look at the typings to
      // solve this
      return value !== null ? listFilter(filter.id, 'within', value as never) : null
    case 'city':
      return cityFilterToListFilter(filter, value as FilterCityValue)
    default:
      // Ideally, we would like to ensure that the value of the filter is a
      // valid value for NonBooleanListFilterValue<ListItem Field> by
      // adding some generic type check.
      // However, this seems to be a bit of a hassle at the moment, so we use type never.
      return value !== null ? listFilter(filter.id, '=', value as never) : null
  }
}

/**
 * Converts an array of {FilterValueItem} to an array of {ListFilter}.
 */
function filterValueItemsToListFilters<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(items: FilterValueItem<Item, FilterField>[], filterMap: FilterConfigMap<Item, FilterField>) {
  return items.reduce((groupItems, item) => {
    const filter = filterTypeToListFilter<Item, FilterField, (typeof filterMap)[FilterField]>(
      filterMap[item.filterId],
      item.value
    )

    // Ignore the filter if the value is null, or we have an empty boolean (and|or|not) filter
    if (filter === null || (isBooleanListFilter(filter) && filter.value.length === 0)) {
      return groupItems
    }

    return groupItems.concat(filter)
  }, new Array<ListFilter<Item>>())
}

/**
 * Converts a {FilterValue} to an array of {ListFilter}. Any filters that have an empty value will
 * be omitted from the result.
 */
export function fromFilterModel<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  filterModel: FilterModel<Item, FilterField> | null,
  filterConfigMap: FilterConfigMap<Item, FilterField>
) {
  if (!filterModel) {
    return []
  }

  return filterModel.reduce((groups, group) => {
    const activeListFilters = filterValueItemsToListFilters<Item, FilterField>(
      group.value,
      filterConfigMap
    )

    if (activeListFilters.length > 0) {
      return groups.concat(listFilter<Item, 'or', ''>('', 'or', activeListFilters))
    }

    return groups
  }, new Array<ListFilter<Item>>())
}
