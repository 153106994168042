import Table, { createTableColumns } from '@app/common/table'
import { Investor, InvestorProfileStrategy } from '@gain/rpc/app-model'
import { useFormatCurrencyRangeCallback } from '@gain/utils/currency'
import { ColumnVisibilityModel, useVisibleColumns } from '@gain/utils/table'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import { MouseEvent, useCallback, useMemo, useRef } from 'react'

import { useOpenLink } from '../../../../common/use-open-link'
import FinancialValue from '../../../../features/financial/financial-value'
import { generateInvestorStrategyPagePathParams } from '../../../utils'
import { DRY_POWDER_EXPLAINER } from '../../investor-profile-util'

const investorStrategiesTableClasses = generateUtilityClasses('InvestorStrategiesTable', [
  'estimate',
])

const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${investorStrategiesTableClasses.estimate}`]: {
    color: theme.palette.info.main,
  },
})) as typeof Table

function useColumns() {
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  return useMemo(() => {
    return createTableColumns<InvestorProfileStrategy>(
      {
        field: 'name',
        headerName: 'Strategy name',
        align: 'left',
        valueFormatter: ({ value }) => value || '-',
      },
      {
        field: 'assetCount',
        headerName: 'Companies',
        align: 'right',
        width: 80,
        valueFormatter: ({ value }) => {
          if (value === 0) {
            return '-'
          }
          return value
        },
      },
      {
        field: 'latestFundSizeEur',
        headerName: 'Latest fund size',
        align: 'right',
        width: 120,
        renderCell: ({ value }) => <FinancialValue amount={value} />,
      },
      {
        field: 'latestFundDryPowderMaxEur',
        headerName: 'Drypowder est.',
        align: 'right',
        width: 148,
        headerExplainer: DRY_POWDER_EXPLAINER,
        cellClassName: ({ row }) => {
          if (row.latestFundDryPowderMinEur !== null && row.latestFundDryPowderMaxEur !== null) {
            return investorStrategiesTableClasses.estimate
          }

          return undefined
        },
        valueFormatter: ({ row }) => {
          const min = row.latestFundDryPowderMinEur
          const max = row.latestFundDryPowderMaxEur

          if (min !== null && max !== null) {
            return formatCurrencyRange(min, max, { round: 'estimate' })
          }

          return '-'
        },
      }
      // TODO:: Enable again once data migration has ran
      // {
      //   field: 'investmentTicketSizeMinEur',
      //   headerName: 'Inv. ticket size',
      //   align: 'right',
      //   width: 148,
      //   valueFormatter: ({ row }) =>
      //     row.investmentTicketSizeMinEur && row.investmentTicketSizeMaxEur
      //       ? formatCurrencyRange(row.investmentTicketSizeMinEur, row.investmentTicketSizeMaxEur)
      //       : '-',
      // }
    )
  }, [formatCurrencyRange])
}

interface InvestorStrategyTableProps {
  investor: Investor
  rows: InvestorProfileStrategy[]
}

export default function InvestorStrategiesTable({ rows, investor }: InvestorStrategyTableProps) {
  const openLink = useOpenLink()
  const tableRef = useRef<HTMLDivElement>(null)

  const columns = useColumns()

  const visibilityModel = useMemo((): ColumnVisibilityModel<InvestorProfileStrategy> => {
    return {
      latestFundDryPowderMaxEur: 700,
      investmentTicketSizeMinEur: 500,
    }
  }, [])

  const visibleColumns = useVisibleColumns(tableRef, columns, visibilityModel)

  const handleRowClick = useCallback(
    (row: InvestorProfileStrategy, event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      if (row.id < 1) {
        return
      }

      const path = generateInvestorStrategyPagePathParams({
        investorId: investor.id,
        investorName: investor.name,
        strategyId: row.id,
        strategyName: row.name,
      })

      openLink(path, event)
    },
    [investor.id, investor.name, openLink]
  )

  const handleIsRowClickable = useCallback((row: InvestorProfileStrategy) => {
    return row.id > 0
  }, [])

  return (
    <StyledTable
      ref={tableRef}
      columns={visibleColumns}
      isRowClickable={handleIsRowClickable}
      onRowClick={handleRowClick}
      rows={rows}
      dense
      disablePaddingBottomOnLastRow
      disableStickyHeader
    />
  )
}
