import { Option } from '@gain/rpc/shared-model'
import { useCallback, useMemo } from 'react'

import SelectMenu from '../../select-menu/select-menu'

export interface ChartSelectProps<Data> {
  label: string
  options: Data[]
  value: Data
  onChange: (value: Data) => void
  getLabel: (option: Data) => string
}

export default function ChartSelect<Data>({
  label,
  options,
  value,
  onChange,
  getLabel,
}: ChartSelectProps<Data>) {
  const formattedOptions = useMemo(() => {
    return options.map((option, index): Option<string> => {
      return {
        label: getLabel(option),
        value: getLabel(option),
      }
    })
  }, [getLabel, options])

  const handleChange = useCallback(
    (selectedValue: string) => {
      const selectedOption = options.find((option) => getLabel(option) === selectedValue)
      if (selectedOption) {
        onChange(selectedOption)
      }
    },
    [getLabel, onChange, options]
  )

  return (
    <SelectMenu
      label={label}
      onChange={handleChange}
      options={formattedOptions}
      value={getLabel(value)}
    />
  )
}
