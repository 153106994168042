import { ChevronDownIcon } from '@gain/components/icons'
import { Option } from '@gain/rpc/shared-model'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { inputBaseClasses } from '@mui/material/InputBase'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent, selectClasses } from '@mui/material/Select'
import { styled } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import { useCallback, useMemo } from 'react'

const selectMenuClasses = generateUtilityClasses('SelectMenu', ['select'])

const StyledRoot = styled('div')(({ theme }) => ({
  flexWrap: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(0.5),

  [`.${selectMenuClasses.select}`]: {
    [`& .${inputBaseClasses.input}`]: {
      ...theme.typography.button,
      padding: 0,
      color: theme.palette.primary.main,
      paddingRight: '20px !important',
    },
    [`&.${inputBaseClasses.focused}`]: {
      [`& .${inputBaseClasses.input}`]: {
        backgroundColor: 'transparent',
      },
    },
    [`& .${selectClasses.select}`]: {
      minHeight: 20,
      maxHeight: 20,
    },
  },
}))

const StyledLabel = styled('label')(({ theme }) => ({
  ...theme.typography.button,
  color: theme.palette.text.secondary,
  whiteSpace: 'nowrap',
}))

const StyledChevronDown = styled(ChevronDownIcon)(({ theme }) => ({
  color: `${theme.palette.primary.main} !important`,
  fontSize: 16,
}))

interface SelectMenuProps<Value> {
  label: string
  options: Array<Option<Value>> | ReadonlyArray<Option<Value>>
  onChange: (value: Value) => void
  value: Value
  sx?: SxProps
}

export default function SelectMenu<Value>({
  label,
  options,
  onChange,
  value,
  sx,
}: SelectMenuProps<Value>) {
  const selectedIndex = useMemo(() => {
    return options.findIndex((option) => option.value === value)
  }, [options, value])

  const handleChange = useCallback(
    (event: SelectChangeEvent<number>) => {
      onChange(options[event.target.value].value)
    },
    [onChange, options]
  )

  // Bail out if the value is not included in the options
  if (selectedIndex === -1) {
    return null
  }

  return (
    <StyledRoot sx={sx}>
      <StyledLabel>{label}:</StyledLabel>
      <Select
        className={selectMenuClasses.select}
        IconComponent={StyledChevronDown}
        onChange={handleChange}
        size={'small'}
        value={selectedIndex}
        variant={'standard'}
        disableUnderline>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={index}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </StyledRoot>
  )
}
