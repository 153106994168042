import {
  useAssetAdvisors,
  useAssetListItem,
  useLegalEntities,
  useListedSecurityChartData,
  useListedSecurityList,
  useSimilarAssets,
} from '@gain/api/app/hooks'
import { Asset, AssetSource } from '@gain/rpc/app-model'
import { AssetOwnershipType, AssetProfileType } from '@gain/rpc/shared-model'
import { listFilter, listFilters } from '@gain/rpc/utils'
import { isDefined } from '@gain/utils/common'
import { useHasAssetDeals } from '@gain/utils/legal-entity'
import { formatISO } from 'date-fns/formatISO'
import { subMonths } from 'date-fns/subMonths'
import { useMemo } from 'react'

import { ProfileTab } from '../../common/profile-tab-bar'
import {
  ASSET_PAGE_ADVISORS_PATH,
  ASSET_PAGE_ASSESSMENT_PATH,
  ASSET_PAGE_BUSINESS_PATH,
  ASSET_PAGE_FINANCIALS_PATH,
  ASSET_PAGE_MARKET_PATH,
  ASSET_PAGE_OWNERSHIP_PATH,
  ASSET_PAGE_SOURCES_PATH,
  ASSET_PAGE_SUMMARY_PATH,
  ASSET_PAGE_VALUATION_PATH,
  generateAssetPagePath,
  useAssetPageParams,
} from '../utils'
import { hasBusiness } from './asset-business'
import { shouldShowFinancialsTab } from './asset-financials'
import { hasFundingMetrics } from './asset-ownership/card-asset-funding/card-asset-funding'

export type AssetSourceTypeKey = keyof Pick<
  AssetSource,
  'financials' | 'business' | 'background' | 'market'
>

export function useAssetSources(
  sources: AssetSource[] | undefined | null,
  ...types: AssetSourceTypeKey[]
) {
  return useMemo(() => {
    if (!isDefined(sources)) {
      return []
    }

    return sources.filter((source) => types.filter((type) => source[type]).length > 0)
  }, [sources, types])
}

function useRelevantEntities(asset?: Asset) {
  const entityIds = asset?.legalEntities.reduce((acc, current) => {
    if (!current.financials) {
      return acc
    }
    return acc.concat(current.legalEntityId)
  }, new Array<number>())

  return useLegalEntities(entityIds || [])
}

export function useHasAssessment(asset?: Asset) {
  const entities = useRelevantEntities(asset)

  if (asset?.profileType !== AssetProfileType.Automated) {
    return true
  } else if (entities.data.some((item) => !!item.rating?.overall)) {
    return true
  }

  return false
}

export function useAssetListedSecurities(asset?: Asset) {
  return useListedSecurityList(() => {
    if (!asset || asset.listedSecurities.length === 0) {
      return null
    }

    return {
      filter: listFilters(
        listFilter(
          'id',
          '=',
          asset.listedSecurities.map(({ listedSecurityId }) => listedSecurityId)
        )
      ),
      limit: asset.listedSecurities.length,
    }
  })
}

/**
 * Only show the valuation tab when there's a listed security and there are at
 * least share prices available for the past month.
 */
export function useHasValuation(asset?: Asset) {
  const swrListedSecurities = useAssetListedSecurities(asset)
  const startDate = useMemo(() => formatISO(subMonths(new Date(), 1)), [])

  // Determine listed security id
  let listedSecurityId: number | undefined
  if (
    swrListedSecurities.data.items.length > 0 &&
    swrListedSecurities.data.items[0].tradingCurrency
  ) {
    listedSecurityId = swrListedSecurities.data.items[0].id
  }

  // Fetch market data for the current month if there's a listed security
  const swrMarketData = useListedSecurityChartData(
    listedSecurityId ? { listedSecurityId, startDate } : null,
    {}
  )

  return swrMarketData.data && swrMarketData.data.sharePrice.length > 0
}

export function useHasRelevantEntityShareholders(asset?: Asset) {
  const entities = useRelevantEntities(asset)
  return entities.data.some((item) => !!item.legalEntityShareholders.length)
}

export function hasOwnership(
  asset: Asset,
  hasDeals: boolean,
  hasShareholders: boolean,
  hasFunding: boolean
): boolean {
  return (
    (asset.profileType === AssetProfileType.Automated &&
      (hasDeals || hasShareholders || hasFunding)) ||
    asset.profileType === AssetProfileType.Limited ||
    asset.profileType === AssetProfileType.Full ||
    !!asset.legalEntities.length
  )
}

export function useAssetPageTabs(asset?: Asset): ProfileTab[] {
  const params = useAssetPageParams()
  const swrAssetListItem = useAssetListItem(params.id)
  const hasAssessment = useHasAssessment(asset)
  const hasShareholders = useHasRelevantEntityShareholders(asset)
  const swrSimilarAssets = useSimilarAssets({ likeAssetId: params.id, limit: 300 })
  const hasDeals = useHasAssetDeals(params.id)
  const swrAssetAdvisors = useAssetAdvisors(params.id)
  const hasValuations =
    asset?.generalInfo?.ownership !== AssetOwnershipType.Listed ||
    asset?.listedSecurities.length > 0

  return useMemo(() => {
    if (!asset) {
      return []
    }

    const sources = asset.sources.length > 0
    const hasFunding = !!(swrAssetListItem.data && hasFundingMetrics(swrAssetListItem.data))
    const hasOwnershipTab = hasOwnership(asset, hasDeals, hasShareholders, hasFunding)
    const hasMarket = Boolean(
      swrSimilarAssets?.data?.items.length || asset.competitors.length || asset.market?.competition
    )
    const hasFinancialResults = shouldShowFinancialsTab(asset)
    const hasAdvisors = Boolean(swrAssetAdvisors.data?.length)

    let hasBusinessTab = hasBusiness(asset)
    // Only show business tab for automated profiles when there are other tabs
    // In the other case, meaning no tab, the asset-automated.component is rendered
    if (asset.profileType === AssetProfileType.Automated && hasBusinessTab) {
      hasBusinessTab =
        hasAssessment || hasFinancialResults || hasMarket || hasOwnershipTab || sources
    }

    const result = new Array<ProfileTab>()

    if (
      hasAssessment ||
      hasAdvisors ||
      hasFinancialResults ||
      hasBusinessTab ||
      hasMarket ||
      hasOwnershipTab ||
      sources
    ) {
      result.push({
        label: 'Summary',
        path: generateAssetPagePath(params, ASSET_PAGE_SUMMARY_PATH),
        value: 'summary',
      })
    }

    if (hasAssessment) {
      result.push({
        label: 'Assessment',
        path: generateAssetPagePath(params, ASSET_PAGE_ASSESSMENT_PATH),
        value: 'assessment',
      })
    }

    if (hasFinancialResults) {
      result.push({
        label: 'Financials',
        path: generateAssetPagePath(params, ASSET_PAGE_FINANCIALS_PATH),
        value: 'financials',
      })
    }

    if (hasBusinessTab) {
      result.push({
        label: 'Business',
        path: generateAssetPagePath(params, ASSET_PAGE_BUSINESS_PATH),
        value: 'business',
      })
    }

    if (hasMarket) {
      result.push({
        label: 'Market',
        path: generateAssetPagePath(params, ASSET_PAGE_MARKET_PATH),
        value: 'market',
      })
    }

    if (hasOwnershipTab) {
      result.push({
        label: 'Ownership',
        path: generateAssetPagePath(params, ASSET_PAGE_OWNERSHIP_PATH),
        value: 'ownership',
      })
    }

    if (hasValuations) {
      result.push({
        label: 'Valuation',
        path: generateAssetPagePath(params, ASSET_PAGE_VALUATION_PATH),
        value: 'valuation',
      })
    }

    if (hasAdvisors) {
      result.push({
        label: 'Advisors',
        path: generateAssetPagePath(params, ASSET_PAGE_ADVISORS_PATH),
        value: 'advisors',
      })
    }

    if (sources) {
      result.push({
        label: 'Sources',
        path: generateAssetPagePath(params, ASSET_PAGE_SOURCES_PATH),
        value: 'sources',
      })
    }

    return result
  }, [
    params,
    asset,
    swrAssetListItem.data,
    hasDeals,
    hasShareholders,
    swrSimilarAssets?.data?.items.length,
    swrAssetAdvisors.data?.length,
    hasAssessment,
    hasValuations,
  ])
}
