import { Investor, InvestorProfileStrategy } from '@gain/rpc/app-model'
import {
  getLabelFromOption,
  INVESTOR_STRATEGY_PREFERRED_STAKE_OPTIONS,
} from '@gain/rpc/shared-model'
import { useFormatCurrencyRangeCallback } from '@gain/utils/currency'
import React from 'react'

import ContentLink from '../../../common/content-link'
import { horizontalTableClasses, RowGroupsConfig } from '../../../common/horizontal-table'
import { AssetRegionsTableCell } from '../../../features/asset/asset-regions-table-cell'
import { AssetSectorsTableCell } from '../../../features/asset/asset-sectors-table-cell'
import FinancialValue from '../../../features/financial/financial-value'
import { generateInvestorStrategyPagePathParams } from '../../utils'
import { DRY_POWDER_EXPLAINER } from '../investor-profile-util'

// function renderClassificationChips(classifications: Array<InvestorStrategyClassification | null>) {
//   return (
//     <OverflowContainer
//       lineHeight={16}
//       maxLines={1}
//       spacing={0.5}>
//       {classifications.filter(Boolean).map((classification, index) => (
//         <Chip
//           key={index}
//           label={getLabelFromOption(INVESTOR_STRATEGY_CLASSIFICATION_OPTIONS, classification)}
//           size={'small'}
//         />
//       ))}
//     </OverflowContainer>
//   )
// }

export function useStrategiesGroups(investor: Investor) {
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  return new Array<RowGroupsConfig<InvestorProfileStrategy>>(
    [
      // TODO:: Enable again once data migration has ran
      // {
      //   label: 'Strategy classification',
      //   bold: false,
      //   renderFn: (row) =>
      //     row.primaryClassification
      //       ? renderClassificationChips([
      //           row.primaryClassification,
      //           row.secondaryClassification,
      //           row.tertiaryClassification,
      //         ])
      //       : '-',
      // },
      {
        label: 'Latest fund',
        bold: false,
        valueFn: (row) => row.latestFundName,
      },
      {
        label: 'Latest fund size',
        renderFn: (row) => <FinancialValue amount={row.latestFundSizeEur} />,
      },
      {
        label: 'Vintage date',
        valueFn: (row) => row.latestFundVintageYear,
      },
      {
        label: 'Drypowder est.',
        explainer: DRY_POWDER_EXPLAINER,
        cellClassName: (row) => {
          if (row.latestFundDryPowderMinEur !== null && row.latestFundDryPowderMaxEur !== null) {
            return horizontalTableClasses.cellEstimate
          }

          return undefined
        },
        valueFn: (row) =>
          row.latestFundDryPowderMinEur !== null && row.latestFundDryPowderMaxEur !== null
            ? formatCurrencyRange(row.latestFundDryPowderMinEur, row.latestFundDryPowderMaxEur, {
                round: 'estimate',
              })
            : '',
      },
    ],

    [
      {
        label: 'Portfolio analysis',
      },
      {
        label: '# of companies',
        renderFn: (row) => (
          <ContentLink
            href={generateInvestorStrategyPagePathParams({
              investorId: investor.id,
              investorName: investor.name,
              strategyId: row.id,
              strategyName: row.name,
            })}
            label={`${row.assetCount}`}
          />
        ),
      },
      {
        label: 'Median EBITDA',
        renderFn: (row) => (
          <FinancialValue
            amount={row.assetEbitdaEurMedian === 0 ? null : row.assetEbitdaEurMedian}
          />
        ),
      },
      {
        label: 'Active in region',
        renderFn: (row) => (
          <AssetRegionsTableCell
            maxItems={6}
            regionNames={row.assetRegions}
            dense
          />
        ),
      },
      {
        label: 'Active in sector',
        renderFn: (row) => (
          <AssetSectorsTableCell
            maxItems={2}
            sectorNames={row.assetSectors}
            dense
          />
        ),
      },
    ],

    [
      {
        label: 'Self-declared',
      },
      {
        label: 'Inv. ticket size',
        valueFn: (row) =>
          row.investmentTicketSizeMinEur && row.investmentTicketSizeMaxEur
            ? formatCurrencyRange(row.investmentTicketSizeMinEur, row.investmentTicketSizeMaxEur)
            : null,
      },
      {
        label: 'EV range',
        valueFn: (row) =>
          row.evRangeMinEur && row.evRangeMaxEur
            ? formatCurrencyRange(row.evRangeMinEur, row.evRangeMaxEur)
            : null,
      },
      {
        label: 'EBITDA range',
        valueFn: (row) =>
          row.ebitdaRangeMinEur && row.ebitdaRangeMaxEur
            ? formatCurrencyRange(row.ebitdaRangeMinEur, row.ebitdaRangeMaxEur)
            : null,
      },
      {
        label: 'Revenue range',
        valueFn: (row) =>
          row.revenueRangeMinEur && row.revenueRangeMaxEur
            ? formatCurrencyRange(row.revenueRangeMinEur, row.revenueRangeMaxEur)
            : null,
      },
      {
        label: 'Equity stake',
        valueFn: (row) =>
          row.preferredEquityStakeMinPct && row.preferredEquityStakeMaxPct
            ? `${row.preferredEquityStakeMinPct}%-${row.preferredEquityStakeMaxPct}%`
            : null,
      },
      {
        label: 'Preferred stake',
        formatFn: (value) =>
          getLabelFromOption(INVESTOR_STRATEGY_PREFERRED_STAKE_OPTIONS, value) || '-',
        // Only return the preferredStake option when one of the other values in the group exists, this is to prevent
        // we show a group with only "-" everywhere because preferredStake has a default value
        valueFn: (row) =>
          row.investmentTicketSizeMinEur ||
          row.investmentTicketSizeMaxEur ||
          row.evRangeMinEur ||
          row.evRangeMaxEur ||
          row.ebitdaRangeMinEur ||
          row.ebitdaRangeMaxEur ||
          row.revenueRangeMinEur ||
          row.preferredEquityStakeMaxPct
            ? row.preferredStake
            : null,
      },
    ]
  )
}
