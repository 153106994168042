import { ChipProps } from '@mui/material/Chip'

export interface Option<TValue> {
  value: TValue
  label: string
}

export interface ChipOption<D> extends Option<D> {
  color: ChipProps['color']
}

export function getOption<D>(options: ReadonlyArray<Option<D>>, value: D): Option<D> | undefined {
  return options.find((option) => option.value === value)
}

export function getLabelFromOption<D>(
  options: ReadonlyArray<Option<D>>,
  value: D
): string | undefined {
  return getOption(options, value)?.label
}
