import { keyframes, styled } from '@mui/material/styles'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React, { forwardRef } from 'react'

import { ReactComponent as Activity } from './assets/activity.svg'
import { ReactComponent as AlertCircle } from './assets/alert-circle.svg'
import { ReactComponent as AlertTriangle } from './assets/alert-triangle.svg'
import { ReactComponent as ArrowDown } from './assets/arrow-down.svg'
import { ReactComponent as ArrowDownRight } from './assets/arrow-down-right.svg'
import { ReactComponent as ArrowLeft } from './assets/arrow-left.svg'
import { ReactComponent as ArrowRight } from './assets/arrow-right.svg'
import { ReactComponent as ArrowUp } from './assets/arrow-up.svg'
import { ReactComponent as ArrowUpRight } from './assets/arrow-up-right.svg'
import { ReactComponent as BadgeCheck } from './assets/badge-check.svg'
import { ReactComponent as BarChart } from './assets/bar-chart.svg'
import { ReactComponent as BarChart2 } from './assets/bar-chart-2.svg'
import { ReactComponent as Bell } from './assets/bell.svg'
import { ReactComponent as BookOpen } from './assets/book-open.svg'
import { ReactComponent as Bookmark } from './assets/bookmark.svg'
import { ReactComponent as BookmarkFilled } from './assets/bookmark-filled.svg'
import { ReactComponent as BookmarkList } from './assets/bookmark-list.svg'
import { ReactComponent as BookmarkMove } from './assets/bookmark-move.svg'
import { ReactComponent as BookmarkRemove } from './assets/bookmark-remove.svg'
import { ReactComponent as Briefcase } from './assets/briefcase.svg'
import { ReactComponent as BriefcaseMoney } from './assets/briefcase-money.svg'
import { ReactComponent as BusinessModel } from './assets/business-model.svg'
import { ReactComponent as Calendar } from './assets/calendar.svg'
import { ReactComponent as Ceo } from './assets/ceo.svg'
import { ReactComponent as Check } from './assets/check.svg'
import { ReactComponent as CheckCircle } from './assets/check-circle.svg'
import { ReactComponent as ChevronDown } from './assets/chevron-down.svg'
import { ReactComponent as ChevronLeft } from './assets/chevron-left.svg'
import { ReactComponent as ChevronLeftEnd } from './assets/chevron-left-end.svg'
import { ReactComponent as ChevronRight } from './assets/chevron-right.svg'
import { ReactComponent as ChevronRightEnd } from './assets/chevron-right-end.svg'
import { ReactComponent as ChevronsRight } from './assets/chevrons-right.svg'
import { ReactComponent as Circle } from './assets/circle.svg'
import { ReactComponent as Clipboard } from './assets/clipboard.svg'
import { ReactComponent as Close } from './assets/close.svg'
import { ReactComponent as CloudOff } from './assets/cloud-off.svg'
import { ReactComponent as Columns } from './assets/columns.svg'
import { ReactComponent as Company } from './assets/company.svg'
import { ReactComponent as Conference } from './assets/conference.svg'
import { ReactComponent as Copy } from './assets/copy.svg'
import { ReactComponent as Crosshair } from './assets/crosshair.svg'
import { ReactComponent as DealCloud } from './assets/dealcloud.svg'
import { ReactComponent as Document } from './assets/document.svg'
import { ReactComponent as Download } from './assets/download.svg'
import { ReactComponent as Edit3 } from './assets/edit-3.svg'
import { ReactComponent as Excel } from './assets/excel.svg'
import { ReactComponent as ExternalLink } from './assets/external-link.svg'
import { ReactComponent as FileText } from './assets/file-text.svg'
import { ReactComponent as Flag } from './assets/flag.svg'
import { ReactComponent as Folder } from './assets/folder.svg'
import { ReactComponent as Fund } from './assets/fund.svg'
import { ReactComponent as Gift } from './assets/gift.svg'
import { ReactComponent as Globe } from './assets/globe.svg'
import { ReactComponent as Hash } from './assets/hash.svg'
import { ReactComponent as HelpCircle } from './assets/help-circle.svg'
import { ReactComponent as Home } from './assets/home.svg'
import { ReactComponent as Html } from './assets/html.svg'
import { ReactComponent as Industry } from './assets/industry.svg'
import { ReactComponent as Info } from './assets/info.svg'
import { ReactComponent as Key } from './assets/key.svg'
import { ReactComponent as Layers } from './assets/layers.svg'
import { ReactComponent as Leaf } from './assets/leaf.svg'
import { ReactComponent as LegalEntity } from './assets/legal-entity.svg'
import { ReactComponent as Lightbulb } from './assets/lightbulb.svg'
import { ReactComponent as Link } from './assets/link.svg'
import { ReactComponent as LinkedIn } from './assets/linkedin.svg'
import { ReactComponent as LinkedInOutlined } from './assets/linkedin-outlined.svg'
import { ReactComponent as LogIn } from './assets/log-in.svg'
import { ReactComponent as LogOut } from './assets/log-out.svg'
import { ReactComponent as Mail } from './assets/mail.svg'
import { ReactComponent as MapPin } from './assets/map-pin.svg'
import { ReactComponent as Maximize2 } from './assets/maximize-2.svg'
import { ReactComponent as Menu } from './assets/menu.svg'
import { ReactComponent as MessageCircle } from './assets/message-circle.svg'
import { ReactComponent as MoneyBag } from './assets/money-bag.svg'
import { ReactComponent as Monitor } from './assets/monitor.svg'
import { ReactComponent as MoreHorizontal } from './assets/more-horizontal.svg'
import { ReactComponent as MoreVertical } from './assets/more-vertical.svg'
import { ReactComponent as Name } from './assets/name.svg'
import { ReactComponent as Pdf } from './assets/pdf.svg'
import { ReactComponent as Phone } from './assets/phone.svg'
import { ReactComponent as PieChart } from './assets/pie-chart.svg'
import { ReactComponent as Pin } from './assets/pin.svg'
import { ReactComponent as PinSelected } from './assets/pin-selected.svg'
import { ReactComponent as Plus } from './assets/plus.svg'
import { ReactComponent as PlusSquare } from './assets/plus-square.svg'
import { ReactComponent as QuestionMark } from './assets/question-mark.svg'
import { ReactComponent as Reset } from './assets/reset.svg'
import { ReactComponent as RotateCcw } from './assets/rotate-ccw.svg'
import { ReactComponent as Scatter } from './assets/scatter.svg'
import { ReactComponent as Search } from './assets/search.svg'
import { ReactComponent as Settings } from './assets/settings.svg'
import { ReactComponent as Shift } from './assets/shift.svg'
import { ReactComponent as Shuffle } from './assets/shuffle.svg'
import { ReactComponent as Slash } from './assets/slash.svg'
import { ReactComponent as Sliders } from './assets/sliders.svg'
import { ReactComponent as Sort } from './assets/sort.svg'
import { ReactComponent as Spinner } from './assets/spinner.svg'
import { ReactComponent as Star } from './assets/star.svg'
import { ReactComponent as StarOutlined } from './assets/star-outlined.svg'
import { ReactComponent as Strategy2 } from './assets/strategy-2.svg'
import { ReactComponent as Table } from './assets/table.svg'
import { ReactComponent as Tag } from './assets/tag.svg'
import { ReactComponent as TagOutlined } from './assets/tag-outlined.svg'
import { ReactComponent as Target } from './assets/target.svg'
import { ReactComponent as Tool } from './assets/tool.svg'
import { ReactComponent as ToolOff } from './assets/tool-off.svg'
import { ReactComponent as Trash2 } from './assets/trash-2.svg'
import { ReactComponent as TrendingUp } from './assets/trending-up.svg'
import { ReactComponent as User } from './assets/user.svg'
import { ReactComponent as UserCheck } from './assets/user-check.svg'
import { ReactComponent as UserClock } from './assets/user-clock.svg'
import { ReactComponent as UserComment } from './assets/user-comment.svg'
import { ReactComponent as UserX } from './assets/user-x.svg'
import { ReactComponent as Users } from './assets/users.svg'
import { ReactComponent as Watchlist } from './assets/watchlist.svg'
import { ReactComponent as WifiOff } from './assets/wifi-off.svg'
import { ReactComponent as X } from './assets/x.svg'
import { ReactComponent as Zap } from './assets/zap.svg'
import { ReactComponent as ZapCircle } from './assets/zap-circle.svg'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledSpinningSvgIcon = styled(SvgIcon)({
  animation: `${rotate} 0.6s linear infinite`,
})

const wrapSpinner = (component: React.ElementType) =>
  forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
    return (
      <StyledSpinningSvgIcon
        ref={ref}
        component={component}
        {...props}
      />
    )
  })

const wrapIcon = (component: React.ElementType) =>
  function (props: SvgIconProps) {
    return (
      <SvgIcon
        component={component}
        {...props}
      />
    )
  }

const StyledFeatherIcon = styled(SvgIcon)({
  fill: 'none',
})

const wrapFeatherIcon = (component: React.ElementType) =>
  forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
    return (
      <StyledFeatherIcon
        ref={ref}
        component={component}
        {...props}
      />
    )
  })

export const WatchlistIcon = wrapIcon(Watchlist)
export const QuestionMarkIcon = wrapIcon(QuestionMark)
export const TagIcon = wrapIcon(Tag)
export const StarIcon = wrapIcon(Star)
export const SpinnerIcon = wrapSpinner(Spinner)
export const DocumentIcon = wrapIcon(Document)
export const PdfIcon = wrapIcon(Pdf)
export const HtmlIcon = wrapIcon(Html)
export const ExcelIcon = wrapIcon(Excel)

/** Feather Icons */
export const ActivityIcon = wrapFeatherIcon(Activity)
export const AlertCircleIcon = wrapFeatherIcon(AlertCircle)
export const AlertTriangleIcon = wrapFeatherIcon(AlertTriangle)
export const ArrowDownIcon = wrapFeatherIcon(ArrowDown)
export const ArrowDownRightIcon = wrapFeatherIcon(ArrowDownRight)
export const ArrowLeftIcon = wrapFeatherIcon(ArrowLeft)
export const ArrowRightIcon = wrapFeatherIcon(ArrowRight)
export const ArrowUpIcon = wrapFeatherIcon(ArrowUp)
export const ArrowUpRightIcon = wrapFeatherIcon(ArrowUpRight)
export const BadgeCheckIcon = wrapFeatherIcon(BadgeCheck)
export const BarChart2Icon = wrapFeatherIcon(BarChart2)
export const BarChartIcon = wrapFeatherIcon(BarChart)
export const BellIcon = wrapFeatherIcon(Bell)
export const BookOpenIcon = wrapFeatherIcon(BookOpen)
export const BookmarkFilledIcon = wrapFeatherIcon(BookmarkFilled)
export const BookmarkIcon = wrapFeatherIcon(Bookmark)
export const BookmarkListIcon = wrapFeatherIcon(BookmarkList)
export const BookmarkMoveIcon = wrapFeatherIcon(BookmarkMove)
export const BookmarkRemoveIcon = wrapFeatherIcon(BookmarkRemove)
export const BriefcaseIcon = wrapFeatherIcon(Briefcase)
export const BriefcaseMoneyIcon = wrapFeatherIcon(BriefcaseMoney)
export const BusinessModelIcon = wrapFeatherIcon(BusinessModel)
export const CalendarIcon = wrapFeatherIcon(Calendar)
export const CeoIcon = wrapFeatherIcon(Ceo)
export const CheckCircleIcon = wrapFeatherIcon(CheckCircle)
export const CheckIcon = wrapFeatherIcon(Check)
export const ChevronDownIcon = wrapFeatherIcon(ChevronDown)
export const ChevronLeftEndIcon = wrapFeatherIcon(ChevronLeftEnd)
export const ChevronLeftIcon = wrapFeatherIcon(ChevronLeft)
export const ChevronRightEndIcon = wrapFeatherIcon(ChevronRightEnd)
export const ChevronRightIcon = wrapFeatherIcon(ChevronRight)
export const ChevronsRightIcon = wrapFeatherIcon(ChevronsRight)
export const CircleIcon = wrapFeatherIcon(Circle)
export const ClipboardIcon = wrapFeatherIcon(Clipboard)
export const CloseIcon = wrapFeatherIcon(Close)
export const CloudOffIcon = wrapFeatherIcon(CloudOff)
export const ColumnsIcon = wrapFeatherIcon(Columns)
export const CompanyIcon = wrapFeatherIcon(Company)
export const ConferenceIcon = wrapFeatherIcon(Conference)
export const CopyIcon = wrapFeatherIcon(Copy)
export const CrosshairIcon = wrapFeatherIcon(Crosshair)
export const DealCloudIcon = wrapFeatherIcon(DealCloud)
export const DownloadIcon = wrapFeatherIcon(Download)
export const Edit3Icon = wrapFeatherIcon(Edit3)
export const ExternalLinkIcon = wrapFeatherIcon(ExternalLink)
export const FileTextIcon = wrapFeatherIcon(FileText)
export const FlagIcon = wrapFeatherIcon(Flag)
export const FolderIcon = wrapFeatherIcon(Folder)
export const FundIcon = wrapFeatherIcon(Fund)
export const GiftIcon = wrapFeatherIcon(Gift)
export const GlobeIcon = wrapFeatherIcon(Globe)
export const HashIcon = wrapFeatherIcon(Hash)
export const HelpCircleIcon = wrapFeatherIcon(HelpCircle)
export const HomeIcon = wrapFeatherIcon(Home)
export const IndustryIcon = wrapFeatherIcon(Industry)
export const InfoIcon = wrapFeatherIcon(Info)
export const KeyIcon = wrapFeatherIcon(Key)
export const LayersIcon = wrapFeatherIcon(Layers)
export const LeafIcon = wrapFeatherIcon(Leaf)
export const LegalEntityIcon = wrapFeatherIcon(LegalEntity)
export const LinkIcon = wrapFeatherIcon(Link)
export const LinkedInIcon = wrapFeatherIcon(LinkedIn)
export const LinkedInOutlinedIcon = wrapFeatherIcon(LinkedInOutlined)
export const LogInIcon = wrapFeatherIcon(LogIn)
export const LogOutIcon = wrapFeatherIcon(LogOut)
export const LightbulbIcon = wrapFeatherIcon(Lightbulb)
export const MailIcon = wrapFeatherIcon(Mail)
export const MapPinIcon = wrapFeatherIcon(MapPin)
export const Maximize2Icon = wrapFeatherIcon(Maximize2)
export const MenuIcon = wrapFeatherIcon(Menu)
export const MessageCircleIcon = wrapFeatherIcon(MessageCircle)
export const MoneyBagIcon = wrapFeatherIcon(MoneyBag)
export const MonitorIcon = wrapFeatherIcon(Monitor)
export const MoreHorizontalIcon = wrapFeatherIcon(MoreHorizontal)
export const MoreVerticalIcon = wrapFeatherIcon(MoreVertical)
export const NameIcon = wrapFeatherIcon(Name)
export const PhoneIcon = wrapFeatherIcon(Phone)
export const PieChartIcon = wrapFeatherIcon(PieChart)
export const PinIcon = wrapFeatherIcon(Pin)
export const PinSelectedIcon = wrapFeatherIcon(PinSelected)
export const PlusIcon = wrapFeatherIcon(Plus)
export const PlusSquareIcon = wrapFeatherIcon(PlusSquare)
export const ResetIcon = wrapFeatherIcon(Reset)
export const RotateCcwIcon = wrapFeatherIcon(RotateCcw)
export const ScatterIcon = wrapFeatherIcon(Scatter)
export const SearchIcon = wrapFeatherIcon(Search)
export const SettingsIcon = wrapFeatherIcon(Settings)
export const ShiftIcon = wrapFeatherIcon(Shift)
export const ShuffleIcon = wrapFeatherIcon(Shuffle)
export const SlashIcon = wrapFeatherIcon(Slash)
export const SlidersIcon = wrapFeatherIcon(Sliders)
export const SortIcon = wrapFeatherIcon(Sort)
export const StarOutlinedIcon = wrapFeatherIcon(StarOutlined)
export const Strategy2Icon = wrapFeatherIcon(Strategy2)
export const TableIcon = wrapFeatherIcon(Table)
export const TagOutlinedIcon = wrapFeatherIcon(TagOutlined)
export const TargetIcon = wrapFeatherIcon(Target)
export const ToolIcon = wrapFeatherIcon(Tool)
export const ToolOffIcon = wrapFeatherIcon(ToolOff)
export const Trash2Icon = wrapFeatherIcon(Trash2)
export const TrendingUpIcon = wrapFeatherIcon(TrendingUp)
export const UserCheckIcon = wrapFeatherIcon(UserCheck)
export const UserClockIcon = wrapFeatherIcon(UserClock)
export const UserCommentIcon = wrapFeatherIcon(UserComment)
export const UserIcon = wrapFeatherIcon(User)
export const UserXIcon = wrapFeatherIcon(UserX)
export const UsersIcon = wrapFeatherIcon(Users)
export const WifiOffIcon = wrapFeatherIcon(WifiOff)
export const XIcon = wrapFeatherIcon(X)
export const ZapCircleIcon = wrapFeatherIcon(ZapCircle)
export const ZapIcon = wrapFeatherIcon(Zap)
