import { useDealList, useFindCurrencyCallback } from '@gain/api/app/hooks'
import { AssetList, DealListItem, Investor } from '@gain/rpc/app-model'
import { listFilter, listFilters } from '@gain/rpc/utils'
import { createListFilterForPastFiveYears } from '@gain/rpc/utils'
import { useFormatCurrencyCallback, useFormatCurrencyRangeCallback } from '@gain/utils/currency'
import React, { useMemo } from 'react'

import Card from '../../../../common/card/card'
import CardMetrics, { createMetrics } from '../../../../common/metrics/metrics-card'
import {
  generateInvestorPagePath,
  INVESTOR_PAGE_ENTRIES_PATH,
  INVESTOR_PAGE_FUNDS_PATH,
  INVESTOR_PAGE_PORTFOLIO_PATH,
  INVESTOR_PAGE_REPRESENTATIVES_PATH,
  useInvestorPageParams,
} from '../../../utils'
import { DRY_POWDER_EXPLAINER } from '../../investor-profile-util'

function useInvestorDealCount(investorId: number) {
  const { data: deals } = useDealList({
    filter: listFilters(
      listFilter<DealListItem>('buyerInvestorIds', '=', investorId),
      createListFilterForPastFiveYears('announcementDate')
    ),
    limit: 0,
  })
  return useMemo(() => deals.counts.filtered || null, [deals.counts])
}

function useDryPowder(investor: Investor) {
  const findCurrency = useFindCurrencyCallback()
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  return useMemo(() => {
    let min = 0
    let max = 0

    for (const fund of investor.funds) {
      if (fund.dryPowderMin === null || fund.dryPowderMax === null) {
        continue
      }

      const currency = findCurrency(fund.currency)
      if (!currency) {
        continue
      }

      min += fund.dryPowderMin * currency.toEur
      max += fund.dryPowderMax * currency.toEur
    }

    if (min === max) {
      return null
    }

    return formatCurrencyRange(min, max, { round: 'estimate' })
  }, [formatCurrencyRange, investor.funds, findCurrency])
}

export interface InvestorMetricsCardProps {
  investor: Investor
  assets: AssetList
  representativesCount: number
}

export default function InvestorMetricsCard({
  investor,
  assets,
  representativesCount,
}: InvestorMetricsCardProps) {
  const investorPageParams = useInvestorPageParams()
  const dealCount = useInvestorDealCount(investor.id)
  const dryPowderRange = useDryPowder(investor)
  const formatCurrency = useFormatCurrencyCallback()

  const latestFundIds = investor.strategies.map((strategy) => strategy.latestFundId).filter(Boolean)

  const metrics = useMemo(() => {
    return createMetrics(
      {
        href: generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_PORTFOLIO_PATH),
        label: 'Companies',
        value: assets.counts.filtered,
      },
      {
        href:
          !!dealCount && generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_ENTRIES_PATH),
        label: 'Platform deals (L5Y)',
        value: dealCount || 0,
      },
      {
        href:
          investor.strategies.length > 0 &&
          generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_FUNDS_PATH),
        label: 'Total live fund size',
        value: formatCurrency(
          investor.funds.reduce((totalActiveFundSize, fund) => {
            if (latestFundIds.includes(fund.id) && fund.fundSizeEur) {
              totalActiveFundSize += fund.fundSizeEur
            }
            return totalActiveFundSize
          }, 0)
        ),
      },
      typeof investor.fundsRaisedLastFiveYears === 'number' &&
        investor.fundsRaisedLastFiveYears > 0 && {
          href: generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_FUNDS_PATH),
          label: 'Fundraising (L5Y)',
          value: formatCurrency(investor.fundsRaisedLastFiveYears),
        },
      dryPowderRange !== null && {
        href: generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_FUNDS_PATH),
        label: 'Drypowder est.',
        value: dryPowderRange,
        explainer: DRY_POWDER_EXPLAINER,
      },
      {
        href:
          representativesCount > 0 &&
          generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_REPRESENTATIVES_PATH),
        label: 'Representatives',
        value: representativesCount,
      }
    )
  }, [
    investorPageParams,
    assets.counts.filtered,
    dealCount,
    investor.strategies.length,
    investor.funds,
    investor.fundsRaisedLastFiveYears,
    formatCurrency,
    dryPowderRange,
    representativesCount,
    latestFundIds,
  ])

  return (
    <Card sx={{ pb: 0 }}>
      <CardMetrics metrics={metrics} />
    </Card>
  )
}
